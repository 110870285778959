.modalMessage{
    font-family: 'Josefin Sans', sans-serif;
}

.modalMessageBiggerText{
    font-size:23px;
    line-height:18px;
}
.modalMessageSelectableText{
    user-select:text;
}
