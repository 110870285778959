#DocumentIconShad {
    position: absolute;
    top:0px;
    left:8px;
    font-size: 40px;
    color:black;
    opacity:.1;
}
#DocumentIcon {
    position: absolute;
    top:-7px;
    left:12px;
    font-size: 40px;
    color: rgb(245, 245, 245);
    /* cursor: alias; */

}
#DocumentIcon:hover {
    /* color:orange; */
    color:white;

    /* opacity:.90; */

}
#DocumentIcon:active {
    /* color:orange; */
    color:rgb(245, 245, 245);
}


/* #TimeIconShad {
    position: absolute;
    top:0px;
    left:48px;
    font-size: 40px;
    color:black;
    opacity:.1;
} */
/* #TimeIcon {
    position: absolute;
    top:-7px;
    left:52px;
    font-size: 40px;
    color:white;
} */
#TimeIcon {
    position: absolute;
    bottom:6px;
    left:9px;
    font-size: 34px;
    /* color:white; */
    color: rgb(245, 245, 245);
    /* color:orangered; */
    opacity:1;
    /* cursor: alias; */


}
#TimeIcon:hover {
    color:white;
}
#TimeIcon:active {
    color:rgb(245, 245, 245);
}


#TimeIconShad {
    position: absolute;
    bottom:1px;
    left:6px;
    font-size: 34px;
    color:black;
    opacity:.1;
    /* opacity:0; */
}
.CardAnchorX{
    position: absolute;
    /* left:21px; */
    top:-20px;
    border-style: dotted;
    /* border-style: ridge; */
    border-radius: 100%;
    width: 32px;
    height:32px;
    border-color: white;
    border-width: 5px;
    opacity:.0;
    /* background-color: white; */
    /* z-index: -10; */
    pointer-events: none;
    
}
.CardAnchor{
    position: absolute;
    /* left:21px; */
    top:-36px;
    /* border-style: dotted; */
    /* border-radius: 100%; */
    width:120px;
    height:50px;
    border-color: white;
    /* border-width: 5px; */
    opacity:0;
    background-color: red;
    /* z-index: -10; */

    border-radius: 20px;
    /* border-top-left-radius: 80px;
    border-top-right-radius: 80px;
       */
    
}
/* .CardAnchor:hover{
    opacity:.2;
    
} */


.CardSeparator{
    width:60%;
    margin:2px auto;
    height:1px;
    background-color:rgba(0, 0, 0, .15); 
    pointer-events: none;


}
.CardTitle {
    font-family: 'Rokkitt', serif;

    text-align: center;
    width:80%;
    font-size: 26px;

    color: black;
    background-color: rgba(0,0,0,0);
    border-style: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/

    outline: none;     
    pointer-events: none;
    border-radius: 5px;
   

}
.CardTitleSel {
    font-family: 'Rokkitt', serif;

    /* margin:auto; */
    text-align: center;
    width: 80%;
    user-select: none; 
    font-size: 26px;
    /* padding: 4px; */
    /* margin:4px; */
    color: black;
    background-color: rgba(0,0,0,0);
    border-style: none;
    /* margin: 0px 20px 20px 0px; */
    user-select: none; 
    outline: none;
    border-radius: 4px;

}

/* .CardInputFocus:focus{
    background-color: rgba(255, 255, 255, .6);
} */




.cardText {
    resize: none;
    overflow: hidden;

    font-family: 'Rokkitt', serif;
    font-size: 14px;

    /* margin: 0px 20px 20px 0px; */

    user-select: none; /* Non-prefixed version, currently*/
    pointer-events: none;

    position: absolute ;
    margin:auto;
    width:calc(100% - 20px);
    left:0px;
    right:0px;
    bottom:10px;
    top:50px;


    /* margin:40px; */
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    /* background-color: rgba(0,0,0,.5); */
    color: black;
    border-style: none;
    text-align: center;

    outline: none;
    padding:10px;
    border-radius: 5px;

}

.cardTextSel {
    resize: none;
    overflow: visible;

    font-family: 'Rokkitt', serif;
    font-size: 14px;

    /* margin: 0px 20px 20px 0px; */

    /* user-select: none;  */
    position: absolute ;
    margin:auto;
    width:calc(100% - 20px);
    left:0px;
    right:0px;
    bottom:10px;
    top:50px;

    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    color: black;
    border-style: none;

    text-align: center;

    outline: none;
    padding:10px;
    border-radius: 5px;
    



}
.cardTextSel:XXXhover{
    /* transition: .1s; */
    background-color: rgba(255, 255, 255, .4);
    /* box-shadow: 1px 6px 19px 1px rgba(0, 0, 0, .2); */
    user-select: none; 
}
.cardTextSel:focus{
    background-color: rgba(255, 0, 0, .6);
    background-color: rgba(255, 255, 255, .6);

}

.CardTitleSel:focus{
    background-color: rgba(255, 255, 255, .6);

}

.indexCard {
    position: absolute;
    padding: 14px 8px 8px 8px;
    box-sizing: border-box;
    height:180px;
    /* width:265px; */
    background-color: rgb(238, 225, 169);

    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, .3);
    font-family:Arial, Helvetica, sans-serif ;

    border-style: solid;
    border-radius: 7px;
    border-color: rgba(0,0,0,0);
    border-width: 6px;

    border-style: none;
    border-radius: 7px;
    border-color: rgba(0,0,0,0);
    border-width: 4px;

    /* font-size: 14px; */

    /* user-select: none;  */

    cursor: pointer;
    box-sizing: border-box;

}



.HoverIndexCard:hover{
    /* transition: .1s; */
    background-color: rgb(245, 234, 184);
    box-shadow: 1px 5px 12px 1px rgba(0, 0, 0, .2);
    /* transition-property: "transform"  .01s; */
    /* transition-property: "box-shadow"  .01s; */
    user-select: none; /* Non-prefixed version, currently*/


}



.NestedCardCounter {
    position: absolute;
    top:-10px;
    right:-8px;
    width: 38px;
    height:38px;

    border-radius: 50%;
    border-color: white;

    border-width: 4px;
    /* border-style: solid; */

    /* background-color: rgb(235, 235, 235); */
    font-family:Arial, Helvetica, sans-serif, ;

    padding: 8px 0px;
    box-sizing: border-box;

    font-size: 19px;

    box-shadow: -2px 6px 0px 0px rgba(0, 0, 0, .1);
    /* cursor: alias; */
    cursor: zoom-in;
    font-weight: bold;
    color: rgb(255, 2555, 255);
    background-color: rgb(124, 174, 255);

}

.NestedCardCounter:hover {
    /* background-color: white; */
    /* background-color: rgb(215, 215, 215); */
    background-color:rgb(230, 216, 155);
    background-color: rgb(184, 214, 219);

    /* box-shadow: inset -5px 12px 10px 0px rgba(0, 0, 0, .2); */
    box-shadow: inset -4px 10px 8px 0px rgba(0, 0, 0, .2);
    /* top:-11px; */
    /* right:-11px; */
    border-style: dashed;
    /* border-color: rgb(124, 174, 255); */
    padding: 6px 0px;
    transform: scale(1.5);
    color: rgba(255, 255, 255, 1);
    /* border-color: orange; */
    border-color: white;
    /* color: rgb(124, 174, 255); */

    background-size: 10px 10px;

    background-image: 
    linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px), 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);

}

.CardScaleHandle{
    position: absolute;
    bottom: 1px;
    right: -9px;
    /* width:20px;
    height:20px; */
    /* background-color:blue; */
    border-left: 20px solid transparent;  /* left arrow slant */
	border-right: 20px solid transparent; /* right arrow slant */
	/* border-bottom: 20px solid black;  */
	border-bottom: 20px solid white; /* bottom, add background color here */
	font-size: 0;
    line-height: 0;
    opacity:0;
    /* transform: rotate 45; */
    transform: rotate(135deg);
    cursor: nw-resize;
}
.CardScaleHandle:hover{
    opacity:1;

}

.card_uid_display_div{
    /* background-color:blue; */
    position: absolute;
    width:100%;
    /* bottom:100% ; */
    top:-30px;
    color:grey;
}